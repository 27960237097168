import React from 'react'



class DevProcess extends React.Component {


  render() {

    return (
      <section className="icon whitelabel-use ieo pt-100 secureof">
        <div className="container">
          <h2 className="main-heading"><span className='bluecolor'>Why Choose</span> Coinsclone for IEO Development?</h2>
          <p className='text-center'>By choosing us for your IEO project, we promise to be your dedicated partner in guiding you through every step of the IEO development process. Together we can accomplish great things and bring your vision to fruition.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                End-to-end IEO Development Services
                  </h3>
                <p className="pharagraph">We provide you the comprehensive end-to-end IEO development services from ideation to execution and beyond. Ensure a hassle-free experience starting from initial planning to the final touch. This includes designing the tokenomics, creating smart contracts, collaborating with exchanges, implementing marketing strategies, and offering post-IEO support.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Talented And Passionate Team
                </h3>
                <p className="pharagraph">With our IEO development team's exceptional skills, we continuously explore novel ideas and strategies to ensure your IEO stands out in the market. We prioritize open communication , collaboration, keeping you informed and engaged throughout your IEO project journey. We are committed to delivering services that exceed your expectations.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Innovative Solution
                </h3>
                <p className="pharagraph">Our IEO development team employs forward-thinking marketplace strategies and techniques to ensure maximum engagement for your IEO project. We captivate the clients with the content, and visuals and also effectively convey your project’s value proposition. </p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Comprehensive Report Of Work
                </h3>
                <p className="pharagraph">As an IEO development company, we provide you with a detailed and transparent report of our work to keep you informed about the progress and status of your IEO project. We document all aspects of our work in the reports, providing a valuable resource for your future reference. Our report of work reflects our commitment to delivering high-quality work and ensuring the success of your IEO venture.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Excellent Technical Support
                </h3>
                <p className="pharagraph">Our dedicated technical team supports your IEO project by quickly encountering any technical issues that arise during the process of IEO development. In addition, we tailor a personalized technical support solution to your IEO project development. We are available 24/7, offering assistance whenever you need it.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Completion Of Project On-Time
                </h3>
                <p className="pharagraph">Our skilled team employs efficient methodologies and strategies to keep your IEO development on track. Coinsclone's primary focus revolves around minimizing delays and ensuring timely completion. Adhering strictly to established timelines and prioritizing the meeting of deadlines is not just a practice but a source of pride for us.</p>
              </div>
              </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DevProcess