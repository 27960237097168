import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

class TechStack extends React.Component {


  render() {
    return (
      <div className='pt-100'>
      <section className="binancestack dev-tech-stack exchange-stack">
        <div className="container">
          <div className="row table-content">
            <div className="col-md-12 col-lg-6 pr-lg-5">
              <h3 className="heading-h2"><span className='bluecolor'>Tech Stack</span> Behind our IEO Software Development</h3>
              <p className="pharagraph">Explore the dynamic tech stack that thrives our IEO development. The Engine of Innovation!
              </p>
            </div>
            <div className="col-md-12 col-lg-6">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.png"
                    alt="Technologies stock"
                    className='logostck'
                    width={573}
                  />
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default TechStack             