import React from "react"

class DevService extends React.Component {

  render() {
    return (

      <section className="dapps ico pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center">IEO Development Services<span className="bluecolor"> We offer</span></h2>
            <p className='text-center'>Rely on our IEO development company to handle every aspect of your IEO project. We provide end-to-end solutions to ensure success and to stay competitive in the market.</p>
          <div className="row mb-3">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>White Paper Sketching</h3>
                            <p className='pharagraph'>As a leading IEO development company, we craft engaging white paper sketching for your investors. Our white paper outlines the goals of your IEO project.  We captivate and inspire your investors.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>IEO Consulting Services</h3>
                            <p className='pharagraph'>We are here to guide you with your IEO project. As an IEO development company, we have an exclusive IEO consulting service where you can get clarity about everything regarding your IEO project.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Exchange Listing</h3>
                            <p className='pharagraph'>We help you with the service of facilitating the exchange listing of your token since it is part of an Initial Exchange Offering (IEO) development process.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Wallet Services</h3>
                            <p className='pharagraph'>Our wallet services offer a secure, user-friendly, and feature-rich crypto wallet solution that enhances the IEO experience for your investors and contributes to your token sale success.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Secure Transactions</h3>
                            <p className='pharagraph'>As an efficient IEO development company, we ensure that all your transactions related to the Initial Exchange Offering (IEO) process are conducted safely and securely.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Technical Support & Maintenance</h3>
                            <p className='pharagraph'>Our IEO experts offer ongoing technical support and maintenance services in monitoring system performance, implementing updates, and resolving any bugs or glitches.</p>
                        </div>
                    </div>
          </div>
        </div>
      </section>
    )
  }
}

export default DevService
