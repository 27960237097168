import React from 'react'


const TypeOf = () => {


  return (
    <section className="typeof ieo pt-100 mb-0">
      <div className="container">
        <div className="row">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Steps We Follow</span> in IEO Platform Development
            </h2>
            <p className="pharagraph head text-center">We build a profit path on your crypto trails. We follow a meticulous Step-by-Step Process of IEO (Initial Exchange Offering) development.
            </p>
        </div>
        <div className='row popular we-offer'>
            <div className='col-md-12 col-lg-3'>
                <h3>Discovering Requirements</h3>
                <p className='pharagraph'>Our IEO development team will focus on your goal, pain points, budget, priorities, etc... Identifying regulatory requirements and legal compliance standards and arriving at the point of what works for you.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>White Paper</h3>
                <p className='pharagraph'>We will create an informative white paper that helps get clarity and attract potential investors to your IEO project. This whitepaper contains your IEO project's concept, technology, tokenomics, roadmap, technical specifications, blockchain platform, smart contracts, and vision.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>IEO Launch</h3>
                <p className='pharagraph'>We finalize and launch your IEO project in the selective exchange and register your crypto project there. Our IEO development team helps with the IEO launch date, token sale mechanics, and monitors the entire approval process.</p>
            </div>
            <div className='col-md-12 col-lg-3'>
                <h3>Post IEO Support</h3>
                <p className='pharagraph'>After successfully launching your project, you can always get exclusive post-IEO support and ongoing updates on token distribution, listing on additional exchanges, and project development progress, etc…</p>
            </div>
        </div>
      </div>
    </section>
  )
}

export default TypeOf